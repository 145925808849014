<template>
  <div class="home">
    <AirDensityTable></AirDensityTable>
  </div>
</template>

<script>
import AirDensityTable from "@/components/AirDensityTable.vue";
// @ is an alias to /src
export default {
  name: "AirDensityCalculation",
  components: {
    AirDensityTable,
  },
};
</script>

<style scoped>
body {
  font-family: Roboto, sans-serif;
}

#chart {
  max-width: 650px;
  margin: 35px auto;
}
</style>
